import {createContext} from 'react';

export interface LocaleInfoTextProps {
  [key: string]: InfoTextProps;
}

export interface LocaleInfoTextContent {
  [key: string]: {cdw: InfoTextContent};
}

export interface InfoTextContent {
  textID: string;
  markdown: string;
  readMoreText?: string;
}

export interface InfoTextItem {
  [key: string]: InfoTextContent;
}

export interface InfoTextProps {
  InfoText: InfoTextItem;
}

export const InfoTextPropsContext = createContext({
  InfoText: {},
} as InfoTextProps);
