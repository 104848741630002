import {useTranslation} from 'next-i18next';
import React from 'react';

import {Box} from 'components/basic-components';
import MarkdownContent from 'components/basic-components/MarkdownContent';

import styles from './styles/DetailedCookieInformationTableContent.module.scss';

const DetailedCookieInformationTableContent = ({cookieInformations}) => {
  const {t} = useTranslation(['consent', 'common']);
  if (!cookieInformations) {
    return null;
  }
  return cookieInformations?.map((information, index) => {
    return (
      <tbody key={information.id}>
        {information?.name && (
          <tr>
            <td className={styles.cookieParameter}>{t('name', {ns: 'common'})}</td>
            <td className={styles.cookieValues}>{information?.name}</td>
          </tr>
        )}
        {information?.provider && (
          <tr>
            <td className={styles.cookieParameter}>{t('provider')}</td>
            <td>
              <MarkdownContent className={styles.tableMarkdown} content={information?.provider} />
            </td>
          </tr>
        )}
        {information?.purpose && (
          <tr>
            <td className={styles.cookieParameter}>{t('purpose')}</td>
            <td>
              <MarkdownContent className={styles.tableMarkdown} content={information?.purpose} />
            </td>
          </tr>
        )}
        {information?.cookieName && (
          <tr>
            <td className={styles.cookieParameter}>{t('cookieName')}</td>
            <td className={styles.cookieValues}>{information?.cookieName}</td>
          </tr>
        )}
        {information?.storageInformation && (
          <tr>
            <td className={styles.cookieParameter}>{t('storageInfo')}</td>
            <td className={styles.cookieValues}>{information?.storageInformation}</td>
          </tr>
        )}
        {information?.cookieDuration && (
          <tr>
            <td className={styles.cookieParameter}>{t('cookieRuntime')}</td>
            <td className={styles.cookieValues}>{information?.cookieDuration}</td>
          </tr>
        )}
        {information?.others && (
          <tr>
            <td className={styles.cookieParameter}>{t('others')}</td>
            <td>
              <MarkdownContent className={styles.tableMarkdown} content={information?.others} />
            </td>
          </tr>
        )}
        <Box
          sx={{height: '50px', display: index === cookieInformations.length - 1 ? 'none' : 'block'}}
        ></Box>
      </tbody>
    );
  });
};

export default DetailedCookieInformationTableContent;
