import {GOOGLE_TAG_MANAGER_ID, NEXT_PUBLIC_COOKIE_BANNER_DELAY, NEXT_PUBLIC_NEXT_FRONTEND_WEIGHT} from 'config/index';
import {DataPrivacyContext} from 'context/dataPrivacyContext';
import {useSession} from 'next-auth/react';
import {useRouter} from 'next/router';
import {createContext, useCallback, useContext, useEffect, useReducer, useState} from 'react';
import {useCookies, withCookies} from 'react-cookie';
import TagManager from 'react-gtm-module';
import {FormProvider, useForm} from 'react-hook-form';

import {customInstance} from 'lib/api/mutator/custom-instance-backend';

import Modal from 'components/basic-components/Modal';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import BasicModal from './BasicModal';
import DetailedModal from './DetailedModal';

const initialState = {
  essentials: true,
  statistics: false,
  marketing: false,
  maps: false,
  youtube: false,
} as {
  essentials: boolean;
  statistics: boolean;
  marketing: boolean;
  maps: boolean;
  youtube: boolean;
};
declare global {
  interface Window {
    dataLayer: any[];
    environment: {[key: string]: any};
    refetchEnvironment: any;
  }
}
export const ConsentContext = createContext({
  openModal: () => {},
  setConsentStateForKey: () => {},
  setConsentState: () => {},
  pushDataLayer: () => {},
  state: {
    essentials: true,
    statistics: false,
    marketing: false,
    maps: false,
    youtube: false,
  },
} as {
  state: {
    essentials: boolean;
    statistics: boolean;
    marketing: boolean;
    maps: boolean;
    youtube: boolean;
  };
  setConsentStateForKey: Function;
  setConsentState: Function;
  pushDataLayer: Function;
  openModal: Function;
});

const ConsentManager = ({children}) => {
  function reducer(state, action) {
    switch (action.type) {
      case 'set_consent_for_key':
        return {...state, [action.key]: action.value};
      case 'init':
        return {...state, ...action.state};
      default:
        throw new Error();
    }
  }
  const {asPath} = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies(['privacy_consent']);
  const [state, dispatch] = useReducer(reducer, cookies['privacy_consent'] || initialState);
  const [open, setOpen] = useState(false 
    // !cookies['privacy_consent'] && !['/datenschutz/', '/impressum/'].includes(asPath),
  );
  const [displayedModal, setDisplayedModal] = useState('basic');
  const [isInitial, setIsInital] = useState(!cookies['privacy_consent']);
  const [hasUsedConsent, setHasUsedConsent] = useState(false);

  const setConsentStateForKey = (key, value) => dispatch({type: 'set_consent_for_key', key, value});
  const setConsentState = state => dispatch({type: 'init', state: state || {}});
  const {data: sessionData} = useSession();
  const formMethods = useForm({defaultValues: state});

  const {reset} = formMethods;
  useEffect(() => {
    let timeout;
    if (
      !open &&
      !cookies['privacy_consent'] &&
      !['/datenschutz/', '/impressum/'].includes(asPath)
    ) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, NEXT_PUBLIC_COOKIE_BANNER_DELAY)
    }
    return () => clearTimeout(timeout);
  }, [asPath]);
  useEffect(() => {
    if (!isInitial) {
      setCookie('privacy_consent', state, {path: '/'});
    }
    if (hasUsedConsent) {
      customInstance({url: window.location.href, noSpinner: true}).catch(err => {
        console.log(err);
      });
    }
  }, [state, isInitial, hasUsedConsent]);
  useEffect(() => {
    if (window && typeof window === 'object') {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        event: 'cookie-consent-management',
        data: {
          ...state,
          etest_variant: 'B',
          esample_size: `${NEXT_PUBLIC_NEXT_FRONTEND_WEIGHT}`,
          log_in_status: sessionData?.user?.accessToken ? 'logged_in' : 'not_logged_in',
        },
      });
    }
  }, [state, sessionData?.user?.accessToken]);

  useEffect(() => {
    if (open) {
      reset(state);
    }
  }, [open]);

  const saveConsent = data => {
    setIsInital(false);
    setConsentState(data);
    setOpen(false);
    setHasUsedConsent(true);

    window.location.reload();
  };

  const acceptAll = data => {
    setIsInital(false);
    for (let key in data) {
      data[key] = true;
    }
    setConsentState(data);
    setOpen(false);
    setHasUsedConsent(true);
  };

  const declineAll = data => {
    setIsInital(false);
    for (let key in data) {
      if (key === 'essentials') {
        data[key] = true;
      } else {
        data[key] = false;
      }
    }
    setConsentState(data);
    setOpen(false);
    setHasUsedConsent(true);
  };

  const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID,
  };

  useEffect(() => {
    if (state.marketing) {
      TagManager.initialize(tagManagerArgs);
    }
  }, [state.marketing]);

  const dataPrivacy = useContext(DataPrivacyContext);
  const pushDataLayer = useCallback(
    data => {
      if (state.marketing) {
        // console.log('GTM PUSH', data);
        if (window && typeof window === 'object') {
          window.dataLayer = window?.dataLayer || [];
          window.dataLayer.push(data);
        }
      }
    },
    [state.marketing],
  );

  const openModal = useCallback(() => setOpen(true), [setOpen]);
  // console.log('render consent', state)

  return (
    <FormProvider {...formMethods}>
      <ConsentContext.Provider
        value={{
          state,
          setConsentStateForKey,
          setConsentState,
          openModal,
          pushDataLayer,
        }}
      >
        <Modal open={open}>
          {displayedModal === 'basic' ? (
            <BasicModal
              dataPrivacyInformationText={dataPrivacy?.dataPrivacyInformationText}
              setOpen={setOpen}
              acceptAll={acceptAll}
              declineAll={declineAll}
              setDisplayedModal={setDisplayedModal}
            />
          ) : (
            <DetailedModal
              acceptAll={acceptAll}
              saveConsent={saveConsent}
              setDisplayedModal={setDisplayedModal}
              dataPrivacyEssentials={dataPrivacy?.dataPrivacyEssentials}
              dataPrivacyStatistics={dataPrivacy?.dataPrivacyStatistics}
              dataPrivacyMarketing={dataPrivacy?.dataPrivacyMarketing}
            />
          )}
        </Modal>
        {children}
      </ConsentContext.Provider>
    </FormProvider>
  );
};

export default withErrorBoundary(withCookies(ConsentManager), 'ConsentManager');
