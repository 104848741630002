import {NEXT_PUBLIC_BASE_URL} from 'config/index';
import {useLocaleprefix} from 'hooks/useLocalePrefix';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React, {FC} from 'react';

import {
  SharedSeoComponent,
  SharedSeoComponentMetaSocialItem,
  SharedSeoComponentMetaSocialItemSocialNetwork,
} from 'lib/api/strapi';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

interface Props {
  seoData: SharedSeoComponent;
}
// Component that Renders the SEO relevant Tags from the SEO Plugin in Strapi
const Seo: FC<Props> = ({seoData: seoDataProp = {}}) => {
  let seoData = seoDataProp;
  let customCanonical = false;
  const {asPath} = useRouter();
  const localePrefix = useLocaleprefix({slash: 'front'});

  const currentUrl = NEXT_PUBLIC_BASE_URL + localePrefix + asPath;
  if (seoData?.metaSocial?.length !== 2) {
    if (seoData && !seoData?.metaSocial?.length) seoData.metaSocial = [];
    if (!seoData) seoData = {metaSocial: []};
    const allNetworks: SharedSeoComponentMetaSocialItemSocialNetwork[] = ['Facebook', 'Twitter'];
    const networks = seoData?.metaSocial?.map(({socialNetwork}) => socialNetwork) || [];
    allNetworks
      .filter(key => !networks.includes(key))
      .forEach((network, index) => {
        seoData?.metaSocial.push({
          socialNetwork: network,
          description: seoData?.metaDescription,
          image: seoData?.metaImage,
          title: seoData?.metaTitle,
          id: index,
        } as SharedSeoComponentMetaSocialItem);
      });
  }
  const mappedComponents = Object.keys(seoData)
    .filter(key => key !== 'id' && !!seoData[key])
    .map(key => {
      switch (key) {
        case 'canonicalURL':
          customCanonical = true;
          return (
            <React.Fragment key={`seo-${seoData.id}-${key}`}>
              <link
                rel="canonical"
                href={
                  (seoData.canonicalURL &&
                    (seoData.canonicalURL?.includes('http')
                      ? seoData.canonicalURL
                      : `${NEXT_PUBLIC_BASE_URL}${localePrefix}/${seoData.canonicalURL}`)) ||
                  currentUrl
                }
              />
              <meta
                property="og:url"
                content={
                  (seoData.canonicalURL &&
                    (seoData.canonicalURL?.includes('http')
                      ? seoData.canonicalURL
                      : `${NEXT_PUBLIC_BASE_URL}${localePrefix}/${seoData.canonicalURL}`)) ||
                  currentUrl
                }
              />
            </React.Fragment>
          );
        case 'keywords':
          return (
            <meta key={`seo-${seoData.id}-${key}`} name="keywords" content={seoData.keywords} />
          );
        case 'metaDescription':
          return (
            <meta
              key={`seo-${seoData.id}-${key}`}
              name="description"
              content={seoData.metaDescription}
            />
          );
        case 'metaImage':
          return (
            <meta
              key={`seo-${seoData.id}-${key}`}
              name="image"
              content={seoData.metaImage.data?.attributes.url}
            />
          );
        case 'metaRobots':
          return (
            <meta key={`seo-${seoData.id}-${key}`} name="robots" content={seoData.metaRobots} />
          );
        case 'metaSocial':
          return seoData.metaSocial.map(socialItem =>
            socialItem.socialNetwork === 'Facebook' ? (
              <React.Fragment key={`seo-${seoData.id}-${socialItem.id}-${key}`}>
                <meta property="og:title" content={socialItem.title} />
                {socialItem?.image?.data?.attributes?.url ? (
                  <meta name="og:image" content={socialItem.image.data?.attributes.url} />
                ) : null}
                <meta property="og:description" content={socialItem.description} />
              </React.Fragment>
            ) : (
              <React.Fragment key={`seo-${seoData.id}-${socialItem.id}-${key}`}>
                <meta name="twitter:title" content={socialItem.title} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={socialItem.description} />
                {socialItem?.image?.data?.attributes?.url ? (
                  <meta name="twitter:image" content={socialItem.image.data?.attributes.url} />
                ) : null}
              </React.Fragment>
            ),
          );
        case 'metaTitle':
          return (
            <React.Fragment key={`seo-${seoData.id}-${key}`}>
              <meta name="title" content={seoData.metaTitle} />
              <title>{seoData.metaTitle}</title>
            </React.Fragment>
          );
        case 'metaViewport':
          return (
            <meta key={`seo-${seoData.id}-${key}`} name="viewport" content={seoData.metaViewport} />
          );
        case 'structuredData':
          return (
            <script
              key={`seo-${seoData.id}-${key}`}
              type="application/ld+json"
              dangerouslySetInnerHTML={{__html: JSON.stringify(seoData.structuredData)}}
            ></script>
          );
        default:
          return null;
      }
    });
  return (
    <Head>
      {!customCanonical ? (
        <React.Fragment>
          <link rel="canonical" href={currentUrl} />
          <meta property="og:url" content={currentUrl} />
        </React.Fragment>
      ) : null}

      {mappedComponents}
    </Head>
  );
};

export default withErrorBoundary(Seo, ' Seo');
