import {useTranslation} from 'next-i18next';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import {Box, Button, Typography} from 'components/basic-components';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {NextLink} from 'components/basic-components/NextLink/Link';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import styles from './styles/BasicModal.module.scss';

const BasicModal = ({
  dataPrivacyInformationText,
  setOpen,
  acceptAll,
  declineAll,
  setDisplayedModal,
}) => {
  const {t} = useTranslation(['consent', 'common']);
  const {handleSubmit} = useFormContext();

  return (
    <ContentWrapper>
      <Box className={styles.modalContainer}>
        <Typography className={styles.headline} component="h2">
          {t('cookieSettings')}
        </Typography>
        <Box className={styles.markdown}>
          <MarkdownContent content={dataPrivacyInformationText} />
        </Box>
        <Box className={styles.linkButtonWrapper}>
          <Box className={styles.linkWrapper}>
            <Typography className={styles.linkText}>
              <NextLink onClick={() => setOpen(false)} href="/datenschutz" passHref>
                {t('privacyPolicy', {ns: 'common'})}
              </NextLink>
            </Typography>
            <Typography className={styles.linkText}>
              <NextLink href="/impressum" passHref onClick={() => setOpen(false)}>
                {t('imprint')}
              </NextLink>
            </Typography>
          </Box>
          <Box className={styles.buttonWrapper}>
            <Button className={styles.button} onClick={handleSubmit(acceptAll)}>
              {t('acceptCookies')}
            </Button>
            <Button
              className={styles.button}
              variant="secondary"
              onClick={handleSubmit(declineAll)}
            >
              {t('rejectCookies')}
            </Button>
            <Button
              className={styles.button}
              variant="secondary"
              onClick={() => setDisplayedModal('detailed')}
              onTouchStart={() => setDisplayedModal('detailed')}
            >
              {t('settings')}
            </Button>
          </Box>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default BasicModal;
