import {createContext} from 'react';

export interface LocaleDataPrivacyProps {
  [key: string]: DataPrivacyProps;
}

export interface DataPrivacyInformation {
  id: number;
  informationText: string;
  cookieInformation: CookieInformation[];
}

export interface CookieInformation {
  id: number;
  name: string;
  cookieName: string;
  cookieDuration: string;
  storageInformation: string;
  provider: string;
  purpose: string;
  others: string;
}

export interface DataPrivacyProps {
  dataPrivacyInformationText: string;
  dataPrivacyEssentials: DataPrivacyInformation;
  dataPrivacyStatistics: DataPrivacyInformation;
  dataPrivacyMarketing: DataPrivacyInformation;
}

export const DataPrivacyContext = createContext({
  dataPrivacyInformationText: '',
  dataPrivacyEssentials: {},
  dataPrivacyStatistics: {},
  dataPrivacyMarketing: {},
} as DataPrivacyProps);
